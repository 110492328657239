@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700');
@import '../../node_modules/ionicons/dist/css/ionicons.min.css';

body {
    margin: 0;
    padding: 0;
    font-family: 'Century Gothic', 'Arial', 'Helvetica', sans-serif;
    font-size: 17px;
}

*,
::before,
::after {
    box-sizing: border-box;
}

h2 {
    font-size: 55px;
    line-height: 67px;
    color: #00bdf2;
    font-weight: bold;
    margin: 15px 0 5px;
}

h3 {
    font-size: 24px;
    line-height: 29px;
    color: #00bdf2;
    font-weight: 300;
    margin: 15px 0;
}

h4,
h5 {
    color: #5f5f5f;
    font-weight: 300;
}

p {
    font-size: 17px;
    line-height: 22px;
    padding-bottom: 30px;
}

.form-control {
    outline: none !important;
    display: inline-block;
    height: 40px;
    border-radius: 4px;
    border: solid 2px #d8d8d8;
    background-color: #ffffff;
    margin: 0 0 10px 0;
    position: relative;
    padding: 0 8px;
    font-size: 15px;
    line-height: 36px;
    color: #808080;
}

label {
    font-size: 15px;
    font-weight: bold;
    color: #5f5f5f;
}

input {
    outline: none !important;
}

button[class*='ion-'] {
    color: #808080;
    outline: none !important;
}
button[class*='ion-']:hover,
button[class*='ion-']:active {
    color: #5f5f5f;
    outline: none !important;
}

@media print {
    body {
        font-size: 13px;
        background: #fff;
        color: #000 !important;
    }

    body * {
        color: #000 !important;
    }

    h1,
    h2 {
        font-size: 25px;
        line-height: 28px;
        font-weight: 300;
        color: #000 !important;
    }

    h3 {
        font-size: 20px;
        line-height: 22px;
        font-weight: 300;
        color: #000 !important;
    }
}
